<script lang="ts" setup>
import type { Entry } from "contentful";
import {
  type TypeEvent,
  isTypeStaff,
  isTypeExternalAuthor,
  type TypeExternalAuthorSkeleton,
  type TypeStaffSkeleton,
} from "~/types/contentful";

defineProps<{
  authors?: Entry<
    TypeExternalAuthorSkeleton | TypeStaffSkeleton,
    "WITHOUT_UNRESOLVABLE_LINKS",
    "en-GB"
  >[];
  event?: TypeEvent<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">;
}>();
</script>

<template>
  <div>
    <div
      v-if="authors?.length || event"
      class="mb-2 text-sm uppercase tracking-widest"
      :class="event ? 'mt-3' : 'mt-4'"
    >
      {{ event ? "Hosted by:" : "Written by:" }}
    </div>

    <div
      v-if="event"
      class="font-bold"
    >
      <TextLink href="">
        {{ event.fields.host }}
      </TextLink>
    </div>

    <div
      v-else
      class="mb-3 block w-full font-bold"
    >
      <template
        v-for="author in authors"
        :key="author.sys.id"
      >
        <TextLink
          :href="
            isTypeStaff(author) && author.fields.currentEmployee
              ? getUrl(author)
              : isTypeExternalAuthor(author) && author.fields.url
                ? author.fields.url
                : ''
          "
          :target="isTypeStaff(author) ? '_self' : '_blank'"
          class="!flex items-center"
        >
          {{ author.fields.name }}
          <NuxtIcon
            v-if="isTypeExternalAuthor(author)"
            name="link-out"
          />
        </TextLink>
      </template>
    </div>
  </div>
</template>
